<template>
  <a-card :bordered="false" style="width: 100%">
    <template #title>
      <div @keydown.enter="queryButton">
        <a-input v-model:value="queryFrom.id" placeholder="请输入UID" addon-before="UID"
                 style="width: 200px"/>
        <a-divider type="vertical"/>
        <a-input v-model:value="queryFrom.username" placeholder="请输入用户名" addon-before="用户名"
                 style="width: 200px"/>
        <a-divider type="vertical"/>
        <a-select v-model:value="queryFrom.status" @change="queryButton" style="width: 200px" placeholder="账户状态"
                  allowClear>
          <a-select-option value="0">正常</a-select-option>
          <a-select-option value="99">封禁</a-select-option>
        </a-select>
        <a-divider type="vertical"/>
        <a-select v-model:value="queryFrom.role" @change="queryButton" style="width: 200px" placeholder="账户身份"
                  allowClear>
          <a-select-option value="admin">管理员</a-select-option>
          <a-select-option value="user">用户</a-select-option>
        </a-select>
        <div style="float: right">
          <a-button type="primary" @click="queryButton">查询</a-button>
        </div>
      </div>
    </template>
    <a-spin :spinning="loading" size="large" tip="获取数据中..." :indicator="indicator">
      <a-table :columns="columns" :data-source="listData" :scroll="{ x: 1300 }"
               :pagination="false" bordered>
        <template #bodyCell="{ column, text ,record}">
          <template v-if="column.dataIndex === 'name'">
            <a>{{ text }}</a>
          </template>
          <template v-if="column.dataIndex === 'status'">
            <span>
          <a-tag v-if="text===0" color="#2db7f5">正常</a-tag>
          <a-tag v-else :color="'#ff0000'">封禁</a-tag>
            </span>
          </template>
          <template v-if="column.dataIndex === 'more'">
            <a-popconfirm
                v-if="store.id!==record.id"
                title="确认删除此用户么？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="delete_user(record.id)"
            >
              <a-typography-link type="danger">删除</a-typography-link>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-spin>
    <a-divider/>
    <a-pagination
        style="float: right"
        v-model:current="queryFrom.page"
        v-model:page-size="queryFrom.rows"
        :total="data.total"
        @change="changePage"
        :hideOnSinglePage="true"
    />
    <div class="data-total">
      <a-typography-paragraph style="margin-bottom: 3px">
        <pre>共计&nbsp;{{ data.total }}&nbsp;个用户</pre>
      </a-typography-paragraph>
    </div>
  </a-card>
</template>
<script setup>
import {h, ref} from "vue";
import {deleteUser, queryUserList} from '@/api/userAPI'
import {LoadingOutlined} from '@ant-design/icons-vue';
import {message} from "ant-design-vue";
import {useUserStore} from "@/pinia/pinia";

const store = useUserStore();
const listData = ref([{}]);
const queryFrom = ref({
  id: '',
  username: '',
  status: null,
  role: null,
  page: 1,
  rows: 10,
})
const data = ref({
  total: null,
})
const loading = ref(false)
const indicator = h(LoadingOutlined, {
  style: {fontSize: '24px',},
  spin: true,
});
const queryButton = () => {
  queryFrom.value.page = 1
  query();
}
const query = () => {
  loading.value = true
  queryUserList(queryFrom.value).then(res => {
    listData.value = res.data.records
    data.value.total = res.data.total
    loading.value = false
  })
}
const changePage = (page, pageSize) => {
  queryFrom.value.page = page;
  queryFrom.value.rows = pageSize;
  query()
};
query()
const delete_user = (id) => {
  deleteUser(id).then(res => {
    if (res.code === 20000) {
      message.success("成功删除用户-" + listData.value.find(item => item.id === id).username);
      if (listData.value.length - 1 > 10) query()
      else queryButton()
    } else {
      message.error(res.message)
    }
  })
};
const columns = [
  {title: 'UID', dataIndex: 'id'},
  {title: '用户名', dataIndex: 'username'},
  {title: '角色', dataIndex: 'role'},
  {title: '简介', dataIndex: 'description'},
  {title: '喜欢类型 ', dataIndex: 'likeType'},
  // {title: '上次活跃时间', dataIndex: 'lastActiveTime'},
  {title: '创建时间', dataIndex: 'createTime'},
  {title: '修改时间', dataIndex: 'updateTime'},
  {title: '状态 ', dataIndex: 'status'},
  {title: '操作 ', dataIndex: 'more'},
];

</script>
