import request from "@/utils/request";


export function getRankBook5() {
    return request.post("/echarts/getRankBook");
}

export function getVisitsRankBook() {
    return request.post("/echarts/getVisitsRankBook");
}

export function getRateRankBook() {
    return request.post("/echarts/getRateRankBook");
}

export function getTypeHotBook() {
    return request.post("/echarts/getTypeHotBook");
}
