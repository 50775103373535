<template>
  <a-card :bordered="false" style="width: 100%">
    <template #title>
      <div @keydown.enter="queryButton">
        <a-input v-model:value="queryFrom.id" placeholder="请输入UID" addon-before="UID"
                 style="width: 200px"/>
        <a-divider type="vertical"/>
        <a-input v-model:value="queryFrom.bookName" placeholder="请输入书名" addon-before="书名"
                 style="width: 200px"/>
        <a-divider type="vertical"/>
        <a-input v-model:value="queryFrom.author" placeholder="请输入作者" addon-before="作者"
                 style="width: 200px"/>
        <a-divider type="vertical"/>
        <a-select v-model:value="queryFrom.type" @change="queryButton" style="width: 200px" placeholder="书籍类型"
                  allowClear>
          <a-select-option value="都市">都市</a-select-option>
          <a-select-option value="科幻">科幻</a-select-option>
          <a-select-option value="修真">修真</a-select-option>
          <a-select-option value="网游">网游</a-select-option>
          <a-select-option value="历史">历史</a-select-option>
          <a-select-option value="玄幻">玄幻</a-select-option>
        </a-select>
        <div style="float: right">
          <a-button type="primary" @click="queryButton">查询</a-button>
        </div>
      </div>
    </template>
    <a-spin :spinning="loading" size="large" tip="获取数据中..." :indicator="indicator">
      <a-table :columns="columns" :data-source="listData" :scroll="{ x: 1300 }"
               :pagination="false" bordered>
        <template #bodyCell="{ column ,record}">
          <template v-if="column.dataIndex === 'more'">
            <a-popconfirm
                :title="'确认删除'+record.bookName+'么？'"
                ok-text="确认"
                cancel-text="取消"
                @confirm="delete_book(record.id)"
            >
              <a-typography-link type="danger">删除</a-typography-link>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-spin>
    <a-divider/>
    <a-pagination
        style="float: right"
        v-model:current="queryFrom.page"
        v-model:page-size="queryFrom.rows"
        :total="data.total"
        @change="changePage"
        :hideOnSinglePage="true"
    />
    <div class="data-total">
      <a-typography-paragraph style="margin-bottom: 3px">
        <pre>共计&nbsp;{{ data.total }}&nbsp;本书籍</pre>
      </a-typography-paragraph>
    </div>
  </a-card>
</template>
<script setup>
import {h, ref} from "vue";
import {LoadingOutlined} from '@ant-design/icons-vue';
import {message} from "ant-design-vue";
import {deleteBook, queryBookList} from "@/api/bookAPI";

const listData = ref([{}]);
const queryFrom = ref({
  id: '',
  bookName: '',
  author: '',
  type: null,
  page: 1,
  rows: 10,
})
const data = ref({
  total: null,
})
const loading = ref(false)
const indicator = h(LoadingOutlined, {
  style: {fontSize: '24px'},
  spin: true,
});
const queryButton = () => {
  queryFrom.value.page = 1
  query();
}
const query = () => {
  loading.value = true
  queryBookList(queryFrom.value).then(res => {
    listData.value = res.data.records
    data.value.total = res.data.total
    loading.value = false
  })
}
const changePage = (page, pageSize) => {
  queryFrom.value.page = page;
  queryFrom.value.rows = pageSize;
  query()
};
query()
const delete_book = (id) => {
  deleteBook(id).then(res => {
    if (res.code === 20000) {
      message.success("成功删除书籍-" + listData.value.find(item => item.id === id).bookName);
      if (listData.value.length - 1 > 10) query()
      else queryButton()
      queryButton()
    } else {
      message.error(res.message)
    }
  })
};
const columns = [
  {title: 'UID', dataIndex: 'id'},
  {title: '书籍名称', dataIndex: 'bookName'},
  {title: '作者', dataIndex: 'author'},
  {title: '类型', dataIndex: 'type'},
  {title: '评分', dataIndex: 'rate'},
  {title: '推荐', dataIndex: 'likeCount'},
  {title: '收藏', dataIndex: 'collectionCount'},
  {title: '加入书架', dataIndex: 'bookshelfCount'},
  {title: '访问量', dataIndex: 'visits'},
  {title: '创建时间', dataIndex: 'createTime'},
  {title: '操作', dataIndex: 'more'},
];
</script>
