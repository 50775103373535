import {defineStore} from 'pinia'
import {getToken, removeToken, setToken} from "@/utils/cookie";
import {message} from "ant-design-vue";
import router from "@/utils/router";
import {getInfo} from "@/api/userAPI";
import {login} from "@/api/loginAPI";

// 你可以任意命名 `defineStore()` 的返回值，但最好使用 store 的名字，同时以 `use` 开头且以 `Store` 结尾。
// (比如 `useUserStore`，`useCartStore`，`useProductStore`)
// 第一个参数是你的应用中 Store 的唯一 ID。
export const useUserStore = defineStore('user', {
    // 其他配置...
    persist: true, // 添加 persist 属性
    state: () => {
        return {
            id: '',
            username: '',
            headImg: '',
            email: '',
            token: getToken(),
        }
    },
    getters: {
        isLogin: () => !this.token,
    },

    actions: {
        login(LoginForm) {
            login(LoginForm).then((res) => {
                // 登录成功后跳转到指定页面
                message.loading("登录中", 1).then(() => {
                    if (res.code === 20000) {
                        message.success("登录成功", 2.5);
                        localStorage.setItem('user_admin_login', JSON.stringify(res.data));
                        res = res.data
                        setToken(res.token)
                        this.id = res.id
                        this.username = res.username
                        this.headImg = res.headImg
                        this.email = res.email
                        this.token = res.token
                        router.push("/home/user-manage");
                    } else {
                        message.error(res.message, 2.5);
                    }
                })
            });
        },
        // 获取用户信息
        getInfo() {
            return new Promise((resolve, reject) => {
                getInfo().then(res => {
                    localStorage.setItem('user_admin_login', JSON.stringify(res.data));
                    res = res.data
                    this.id = res.id
                    this.username = res.username
                    this.headImg = res.headImg
                    this.email = res.email
                }).catch(error => {
                    console.log(error)
                    reject(error)
                })
            })
        },
        // 前端 登出
        fedLogOut() {
            return new Promise(resolve => {
                this.id = ''
                this.token = ''
                removeToken()
                resolve()
            })
        }
    }
})
