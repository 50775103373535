import {createRouter, createWebHashHistory,} from "vue-router";
import {message} from "ant-design-vue";
import LoginPage from "@/pages/LoginPages/LoginPage.vue";
import HomePage from "@/pages/Home/HomePage.vue";
import HomeIndex from "@/pages/HomePages/HomeIndex.vue";
import {getToken} from "@/utils/cookie";
import UserList from "@/pages/HomePages/UserList.vue";
import BookList from "@/pages/HomePages/BookList.vue";
import CommentList from "@/pages/HomePages/CommentList.vue";
import EchartsPage from "@/pages/HomePages/EchartsPage.vue";


const routes = [
    {path: "/", redirect: "/home/index"},
    {path: "/login", component: LoginPage, meta: {title: "登录"}},
    {
        path: "/home",
        component: HomePage,
        meta: {requiresAuth: true, title: "主页"},
        children: [
            {path: "index", component: HomeIndex, meta: {title: "主页"}},
            {path: "user-manage", component: UserList, meta: {title: "用户管理"}},
            {path: "book-manage", component: BookList, meta: {title: "书籍管理"}},
            {path: "comment-manage", component: CommentList, meta: {title: "评论管理"}},
            {path: "chart", component: EchartsPage, meta: {title: "网站数据展示"}},
        ]
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = (to.meta && to.meta.title) || "默认标题";

    if (to.matched.some(record => record.meta.requiresAuth)) { // 判断该路由是否需要进行鉴权
        // 获取token
        if (getToken() === undefined) { // token不存在，则跳转到登录页
            message.error("请先登录")
            next('/login');
        } else {
            next();
        }
    } else {  // 不需要进行鉴权，直接进行下一步路由
        next();
    }
});


export default router;
