<template>
  <transition name="fade" mode="out-in">
    <a-config-provider :locale="zhCN">
      <router-view/>
    </a-config-provider>
  </transition>
  <a-back-top/>
</template>

<style>
.ant-image-img {
  cursor: pointer;
  transition: all 0.6s;
}

.ant-image-img:hover {
  border-radius: 5px;
  transform: scale(1.2);
}

.data-total {
  display: flex; /* 使用 Flex 布局 */
  float: right;
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
  height: 35px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

div {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
</style>
<script setup>
//注意这里引用中文包的路径与上方不同，这里不需要做其他操作，直接复制代码即可
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn');
</script>
