<template>
  <div class="login">
    <div class="card" @keyup.enter="Login">
      <div>
        <!-- 标题 -->
        <h1 class="center">Manage Sign in</h1>

        <MyDivider/>
        <div class="center">
          <a-input
              v-model:value="LoginForm.username"
              placeholder="请输入用户名"
              style="width: 350px; height: 50px"
          >
            <template #prefix>
              <user-outlined/>
            </template>
          </a-input>
          <br/>
          <a-input-password
              v-model:value="LoginForm.password"
              placeholder="请输入密码"
              style="width: 350px; height: 50px"
          >
            <template #prefix>
              <LockOutlined/>
            </template>
          </a-input-password>
        </div>
        <br/>
        <!-- 记住登录 -->
        <div style="display: flex; justify-content: space-between">
          <a-checkbox v-model:checked="LoginForm.remember">记住登录</a-checkbox>
        </div>
        <br/>
        <!-- 登录按钮 -->
        <div class="center">
          <a-button
              type="primary"
              shape="round"
              size="large"
              style="width: 275px; height: 40px"
              @click="Login"
          >登入
          </a-button>
          <MyDivider/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {LockOutlined, UserOutlined} from "@ant-design/icons-vue";
import {ref} from "vue";
import MyDivider from "@/components/Divider/HDivider.vue";
import 'animate.css';
import {useUserStore} from "@/pinia/pinia";

const store = useUserStore();

const LoginForm = ref({
  username: "huanghun",
  password: "123456",
  remember: false,
});

const Login = () => {
  store.login(LoginForm.value)
};
</script>

<style scoped>
.login {
  display: grid;
  place-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/登录背景.jpeg");
  background-size: cover; /* 背景图像的尺寸 */
  background-attachment: fixed; /* 背景图像固定不动 */
}

.center {
  display: grid;
  place-items: center;
}

.card {
  display: grid;
  place-items: center;
  width: 470px;
  height: 500px;
  background: #fdfdfd;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease; /* 添加 box-shadow 的过渡效果 */
}

.card:hover {
  scroll-behavior: smooth;
  transform: translate(-5px, -5px); /* 向上浮动10像素 */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

h1 {
  display: grid;
  place-items: center;
  font-size: 38px;
  margin-bottom: 24px;
  font-weight: bold;
}
</style>
