import axios from "axios";
import Qs from 'qs'
import router from "@/utils/router";
import {message} from "ant-design-vue";
import {getToken} from "@/utils/cookie";

const request = axios.create({
    baseURL: "/api", // 原始url地址
    timeout: 10000, // 超时时间
    headers: {}, // 请求头信息
    transformRequest: [
        function (data) {
            if (data instanceof FormData) {
                return data
            }
            return Qs.stringify(data, {arrayFormat: 'brackets'})
        },
    ],
});

// 添加请求拦截器
request.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        config.headers['S-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
request.interceptors.response.use(
    function (response) {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        const data = response.data;
        if (data.code === 50008) {
            message.error(data.message)
            router.push("/login")
        } else if (data.code.toString().startsWith('403')) {
            message.error(data.message)
            router.push("/login")
        } else {
            console.log("data.code"+data.code)
            console.log("返回了根本数据");
            return data;
        }
    },
    function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        console.log(error.statusCode);
        message.error("服务端异常")
    }
);
export default request;
