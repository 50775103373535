import request from "@/utils/request";

export function liuda() {
    return request.post("/user/liuda");
}

export function getInfo() {
    return request.post("/user/getInfo");
}

export function queryUserList(from) {
    return request.post("/user/queryUserList", from);
}

export function deleteUser(id) {
    return request.post("/user/delete/" + id);
}


