<script setup>

</script>

<template>
  <div>
    <a-card :bordered="false" style="width: 100%">
      <a-typography-title>欢迎进入【阅读指南针】后台管理界面</a-typography-title>
    </a-card>
  </div>
</template>

<style scoped>

</style>
