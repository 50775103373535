import request from "@/utils/request";

export function queryBookList(from) {
    return request.post("/book/queryBookList", from);
}

export function deleteBook(id) {
    return request.post("/book/delete" + id,);
}


