<template>
  <a-layout style="min-height: 100vh">
    <a-layout-header class="header">
      <div class="logo"/>
      <div class="avatar">
        <a-avatar :size="48" :src="store.headImg"
                  alt="头"/>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            {{ store.username }}
            <DownOutlined/>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a @click="LogOut">退出登录</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </a-layout-header>
    <a-layout>
      <a-layout-sider style="" v-model:collapsed="collapsed" collapsible>
        <a-affix :offset-top="64">
          <a-menu v-model:selectedKeys="sideKeys" theme="dark" mode="inline">
            <a-menu-item key="index">
              <router-link to="/home/index">
                <HomeOutlined/>
                <span>主页</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="user-manage">
              <router-link to="/home/user-manage">
                <user-outlined/>
                <span>用户管理</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="book-manage">
              <router-link to="/home/book-manage">
                <BookOutlined/>
                <span>书籍管理</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="comment-manage">
              <router-link to="/home/comment-manage">
                <CommentOutlined/>
                <span>评论管理</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="chart">
              <router-link to="/home/chart">
                <AreaChartOutlined/>
                <span>网站数据图表</span>
              </router-link>
            </a-menu-item>
          </a-menu>
        </a-affix>
      </a-layout-sider>
      <a-layout style="padding: 24px;background: #e8e8e8">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component"/>
          </transition>
        </router-view>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script setup>
import {ref} from 'vue';
import {
  AreaChartOutlined,
  BookOutlined,
  CommentOutlined,
  DownOutlined,
  HomeOutlined,
  UserOutlined
} from '@ant-design/icons-vue';
import {useUserStore} from "@/pinia/pinia";
import {logout} from "@/api/loginAPI";
import {message} from "ant-design-vue";
import router from "@/utils/router";
import {removeToken} from "@/utils/cookie";

const store = useUserStore();
const collapsed = ref(false);
// 获取页面二级url来确定key
const sideKeys = ref([window.location.href.substring(window.location.href.indexOf('/home/') + '/home/'.length)]);
const LogOut = () => {
  logout().then(res => {
    message.success(res.message)
    router.push("/login")
    removeToken()
  })
}
store.getInfo()
</script>
<style scoped>
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 32px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}


.avatar {
  cursor: pointer;
  float: right;
}

/* 路由切换动画 */
/* fade-transform */
.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s;
}

/* 可能为enter失效，拆分为 enter-from和enter-to */
.fade-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.fade-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

.fade-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

</style>
