import request from "@/utils/request";

export function queryCommentList(from) {
    return request.post("/comment/queryCommentList", from);
}

export function examinedComment(id) {
    return request.post("/comment/examined/" + id);
}


