import {createApp} from 'vue'
import App from './App.vue'
import Antd from "ant-design-vue";
import router from "@/utils/router";
import "ant-design-vue/dist/reset.css";
import {createPinia} from 'pinia'

import piniaPluginPersist from 'pinia-plugin-persist'

const pinia = createPinia()
const app = createApp(App);
pinia.use(piniaPluginPersist)
app.use(Antd)
    .use(router)
    .use(pinia)
    .provide("pageTitle", "默认标题")
    .mount("#app");

