<template>
  <a-card :bordered="false" style="width: 100%">
    <template #title>
      <div @keydown.enter="queryButton">
        <a-input v-model:value="queryFrom.bookId" placeholder="请输入书籍UID" addon-before="书籍UID"
                 style="width: 200px"/>
        <a-divider type="vertical"/>
        <a-input v-model:value="queryFrom.userId" placeholder="请输入评论用户UID" addon-before="用户UID"
                 style="width: 300px"/>
        <a-divider type="vertical"/>
        <div style="float: right">
          <a-button type="primary" @click="queryButton">查询</a-button>
        </div>
      </div>
    </template>
    <a-spin :spinning="loading" size="large" tip="获取数据中..." :indicator="indicator">
      <a-table :columns="columns" :data-source="listData" :scroll="{ x: 1300 }"
               :pagination="false" bordered size="middle">
        <template #bodyCell="{ column,record }">
          <template v-if="column.dataIndex === 'more'">
            <a-button type="link" @click="examined(record.id)">审核</a-button>
          </template>
        </template>
      </a-table>
    </a-spin>
    <a-divider/>
    <a-pagination
        style="float: right"
        v-model:current="queryFrom.page"
        v-model:page-size="queryFrom.rows"
        :total="data.total"
        @change="changePage"
        :hideOnSinglePage="true"
    />
    <div class="data-total">
      <a-typography-paragraph style="margin-bottom: 3px">
        <pre>共计&nbsp;{{ data.total }}&nbsp;条评论</pre>
      </a-typography-paragraph>
    </div>
  </a-card>
</template>
<script setup>
import {h, ref} from "vue";
import {LoadingOutlined} from '@ant-design/icons-vue';
import {message} from "ant-design-vue";
import {examinedComment, queryCommentList} from "@/api/commentAPI";

const listData = ref([{}]);
const queryFrom = ref({
  id: '',
  bookId: '',
  userId: '',
  page: 1,
  rows: 10,
})
const data = ref({
  total: null,
})
const loading = ref(false)
const indicator = h(LoadingOutlined, {
  style: {fontSize: '24px'},
  spin: true,
});
const queryButton = () => {
  queryFrom.value.page = 1
  query();
}
const query = () => {
  loading.value = true
  queryCommentList(queryFrom.value).then(res => {
    listData.value = res.data.records
    data.value.total = res.data.total
    loading.value = false
  })
}
const changePage = (page, pageSize) => {
  queryFrom.value.page = page;
  queryFrom.value.rows = pageSize;
  query()
};
query()
const examined = (id) => {
  examinedComment(id).then(res => {
    if (res.code === 20000) {
      message.success("审核成功")
      if (listData.value.length - 1 > 10) query()
      else queryButton()
    } else {
      message.error(res.message)
    }
  })
};
const columns = [
  {title: '书籍UID', dataIndex: 'bookId'},
  {title: '评论用户UID', dataIndex: 'userId'},
  {title: '评论内容', dataIndex: 'content'},
  {title: '评论时间', dataIndex: 'createTime'},
  {title: '操作', dataIndex: 'more'},
];
</script>
